@import "@css/supplemental/locals.css";
/* local variables overwrite */

:root {
	/* --badge-size: 2rem; */
	/* --badge-padding: 0 4px; */
	--badge-background: var(--color-red);
	/* --badge-color: var(--color-white); */
	/* --badge-font-weight: var(--font-weight-bold); */
	/* --badge-font-size: var(--font-size-small); */
	/* --badge-font-family: var(--font-family); */

	/* --bubble-width: 150px; */
	/* --bubble-height: 90px; */
	/* --bubble-border-radius: 6px; */
	/* --bubble-border: 1px solid rgba(0,0,0,.2); */
	/* --bubble-background: var(--color-white); */
	/* --bubble-color: var(--color-black); */

	/* --card-section-margin: 0 0 2rem; */
	/* --card-section-padding: 0 2rem; */
	/* --card-section__header-letter-spacing: initial; */
	/* --card-section__header-text-transform: none; */
	/* --card-section__header-font-family: var(--font-family); */
	/* --card-section__header-font-weight: var(--font-weight-bold); */
	/* --card-section__header-font-size: var(--font-size-large); */
	--card-section__header-color: var(--color-blue);
	/* --card-section__header-margin-bottom: 0.5rem; */
	/* --card-section__content-font-size: var(--font-size); */
	/* --card-section__content-padding: 0; */
	--card-section__content-color: var(--color-grey-dark);

	/* --icon-label__label-color: var(--color-black); */
	/* --icon-label__label-color-hover: var(--color-primary); */
	/* --icon-label__icon-width: 19px; */
	/* --icon-label__icon-height: 22px; */

	/* --notice-margin: 1rem 0; */
	/* --notice-padding: 1rem 1.5rem; */
	--notice-background: var(--color-orange);
	/* --notice-border-radius: 0; */
	/* --notice-font-size: var(--font-size); */
	/* --notice-color: var(--color-black); */

	/* --tooltip-background: var(--color-white); */
	/* --tooltip-border-radius: 6px; */
	/* --tooltip-width: 220px; */
	/* --tooltip-padding: 5px 0; */
	/* --tooltip-text-align: center; */
}
