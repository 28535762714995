@import "variables.css";
@import "@css/widget/message.css";

.message {
	display: flex;
	flex-direction: column;
}

.message__date {
	color: var(--message-color-primary);
	font-family: var(--font-family-regular);
	margin-left: 0px;
	font-weight: 400;
	order: 2;
	font-size: 14px;
}

.message__content {
	font-family: var(--font-family-regular);
	font-size: 14px;
	order: 1;
}

.message__icon {
	display: none;
}

.message__anchor {
	position: relative;
	padding: 2rem 0;
}

.message__anchor:after {
	content: '';
	display: block;
	position: absolute;
	top: calc(50% - 1rem);
	right: 0;
	width: 2rem;
	height: 2rem;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='10' cy='10' r='10' fill='%230099ED'/%3E%3Cpath fill='%23FFF' d='M12.648 10.5a.27.27 0 0 1-.078.18l-3.64 3.64a.27.27 0 0 1-.18.078.27.27 0 0 1-.18-.078l-.39-.39a.254.254 0 0 1-.078-.18.27.27 0 0 1 .078-.18l3.07-3.07-3.07-3.07a.27.27 0 0 1-.078-.18.27.27 0 0 1 .078-.18l.39-.39a.27.27 0 0 1 .18-.078.27.27 0 0 1 .18.078l3.64 3.64a.27.27 0 0 1 .078.18z'/%3E%3C/g%3E%3C/svg%3E");
}