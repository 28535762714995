@import "variables.css";

/* These classes work for the default icons */

.icon.icon--black .stroke {
	stroke: var(--color-black);
}
.icon.icon--black .fill {
	fill: var(--color-black);
}

.icon.icon--grey .stroke {
	stroke: var(--color-grey);
}
.icon.icon--grey .fill {
	fill: var(--color-grey);
}

.icon.icon--white .stroke {
	stroke: var(--color-white);
}
.icon.icon--white .fill {
	fill: var(--color-white);
}

.icon.icon--primary .stroke {
	stroke: var(--color-primary);
}
.icon.icon--primary .fill {
	fill: var(--color-primary);
}

.icon.icon--blue .stroke {
	stroke: var(--color-blue);
}
.icon.icon--blue .fill {
	fill: var(--color-blue);
}

.icon.icon--orange .stroke {
	stroke: var(--color-orange);
}
.icon.icon--orange .fill {
	fill: var(--color-orange);
}

/* .icon.icon--secondary .stroke {
	stroke: var(--color-secondary);
}
.icon.icon--secondary .fill {
	fill: var(--color-secondary);
} */