@import "variables.css";

/* Overwriting user-agent input styling */
input[type="radio"] {
	width: 20px;
	margin: 0;
}

input[type="radio"],
input[type="checkbox"] {
	position: relative;
}

input[type="radio"]:before {
	background-color: var(--color-white);
	border-radius: 50%;
	border: 2px solid var(--color-black);
	content: '';
	cursor: pointer;
	display: block;
	height: 12px;
	left: 0px;
	position: absolute;
	top: -1px;
	width: 12px;
	box-sizing: content-box;
}

input[type="radio"]:checked:before {
	border: 5px solid var(--color-primary);
	height: 6px;
	width: 6px;
}

input[type="checkbox"].checkbox {
	position: absolute;
}

input[type="checkbox"] {
	left: 1px;
	top: 3px;
	-ms-transform: translateX(-2px);
	transform: translateX(-2px);
	width: 11px;
	cursor: pointer;
}

input[type="checkbox"]:before {
	position: absolute;
	top: -1px;
	left: -3px;
	content: '';
	border: 2px solid var(--color-black);
	width: 1rem;
	height: 1rem;
	border-radius: 4px;
	box-sizing: content-box;
	background-color: #fff;
	transition: background-color 200ms;
}

input[type="checkbox"]:checked:before {
	background-color: var(--color-primary-dark);
	border-color: var(--color-primary-dark);
	background-image: url("~assets/svg/checkmark.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}

input[type="number"],
input[type="text"] {
	width: 100%;
	border: 2px solid var(--color-grey);
	line-height: 20px;
	padding: 0.75rem 1rem;
	font-size: var(--font-size);
	font-family: var(--font-family);
	min-height: 48px;
	font-weight: var(--font-weight);
	transition: none;
}

input[type="text"]:focus,
input[type="text"]:hover,
input[type="radio"]:hover:before,
input[type="checkbox"]:hover:before {
	border-color: var(--color-black);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
