:root {
	/* STANDARD GLOBAL VARIABLES */
	/* colors */
	--color-primary-light: #E13CE5;
	--color-primary: #00CFBB;
	--color-primary-dark: #023F99;

	--color-white: #ffffff;
	--color-grey-verylight: #eeeeee;
	--color-grey-light: #aca5ac;
	--color-grey: #646064;
	--color-grey-dark: #423e42;
	--color-black: #180019;

	--color-warning: #C90000;

	/* gradients */
	--gradient-primary: linear-gradient(110deg, #00CFBB 0, #023F99 100%);
	--gradient-secondary: linear-gradient(110deg, #00CFBB 0, #023F99 80%, #E13CE5 100%);

	/* shadows */
	--shadow-small: 0 2px 4px 0 rgba(225, 220, 203, 0.40), 0 2px 4px 0 rgba(42, 41, 46, 0.16);
	--shadow-large: 0 0 16px 0 rgba(195, 182, 141, 0.16), 0 0 16px 0 rgba(42, 41, 46, 0.08);

	/* fonts */
	--font-family: "Avenir", sans-serif;
	--font-family-secondary: "Memphis", sans-serif;

	--font-weight-bold: 700;
	--font-weight-light: 300;
	--font-weight: 400;

	--font-size-small: 12px;
	--font-size: 16px;
	--font-size-large: 20px;
	--font-size-heading-3: 22px;
	--font-size-heading-2: 28px;
	--font-size-heading-1: 38px;
	--font-size-heading: 48px;

	/* font-style font-variant font-weight font-size/line-height font-family */
	--font-small: normal normal var(--font-weight) var(--font-size-small)/17px var(--font-family);
	--font: normal normal var(--font-weight) var(--font-size)/24px var(--font-family);
	--font-large: normal normal var(--font-weight) var(--font-size-large)/32px var(--font-family);
	--font-heading-3: normal normal var(--font-weight) var(--font-size-heading-3)/22px var(--font-family-secondary);
	--font-heading-2: normal normal var(--font-weight) var(--font-size-heading-2)/32px var(--font-family-secondary);
	--font-heading-1: normal normal var(--font-weight) var(--font-size-heading-1)/44px var(--font-family-secondary);

	/* transitions */
	--transition-duration-short: 200ms;
	--transition-duration-long: 500ms;

	/* borders */
	--border-radius: 2rem;
}