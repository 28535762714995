@import "widget/locals.css";

.message {
	display: block;
	color: var(--message-color-primary);
	text-decoration: none;
	cursor: pointer;
}

.message.unread {
	font-weight: var(--font-weight-bold);
}

.message__date {
	margin-left: 3.4rem;
	color: var(--message-color-secondary);
	font-size: var(--font-size-small);
	letter-spacing: var(--message__date-letter-spacing);
}

.message__content {
	display: flex;
	align-items: flex-start;
	font-size: var(--font-size);
}

.message.unread .message__content {
	font-weight: var(--font-weight-bold);
}

.message__icon {
	overflow: hidden;
	flex-shrink: 0;
	width: 1.8rem;
	margin-right: 1.5rem;
}

.message__icon .icon .fill {
	fill: var(--message-color-secondary);
}

.message.unread .message__icon .icon .fill {
	fill: var(--message-color-primary);
}