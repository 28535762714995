@import "variables.css";
@import "input.css";
@import "icon.css";
@import "dependencies/flexboxgrid.css";

*, *::before, *::after {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

*:focus-visible {
	outline: 3px dotted black;
	outline-offset: 2px;
}

html {
	font-size: 62.5%;
	overflow-y: scroll;
}

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	color: var(--color-black);
	font: var(--font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

@media screen and (min-width: 1024px) {
	body {
		min-height: 100vh;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

h1 {
	font: var(--font-heading-1);
}

h2 {
	font: var(--font-heading-2);
}

h3, h4, h5, h6 {
	font: var(--font-heading-3);
}

a {
	color: var(--color-primary);
}

a:hover {
	text-decoration: none;
}

a.forbg {
	color: var(--color-white);
}

ul {
	padding-left: 2rem;
	list-style: none;
}

#root {
	height: 100%;
}

.logo {
	display: block;
	margin: auto;
	margin-right: 0;
	height: 4rem;
	cursor: pointer;
}

.container {
	width: 100%;
	max-width: 114rem;
	padding: 0 1.5rem;
	margin: 0 auto;
}

.container--detail {
	background: var(--color-white);
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	padding: 20px 0 20px 0;
	/* min-height: calc(100vh - calc(var(--header-height) + var(--footer-height) - 3px)); */
	flex-grow: 1;
}

.content-wrapper {
	display: flex;
	flex-direction: column;
	/* height: 100%; */
	min-height: 100vh;
	padding-top: var(--header-height); /* Nav height + spacing */
}

.content {
	flex-grow: 1;
	flex-shrink: 0;
	/* min-height: calc(100vh - calc(var(--header-height) + var(--footer-height) - 3px)); */
}

.content .content__title {
	margin-top: 40px;
	margin-bottom: 40px;
}

.side--bar a {
	font-size: 14px;
}

.side--bar-link {
	padding-bottom: 20px;
	display: inline-block;
}

.content--card {
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(0, 0, 0, 0.1);
	margin-top: 30px;
}

.content--card:first-of-type {
	margin: 0;
}

.content--card:last-of-type {
	margin-bottom: 6rem;
}

input[type=checkbox] {
	margin-right: 10px;
	font-size: var(--font-size);
	outline-offset: 5px;
}

input[type=checkbox]:before {
	border-radius: 0;
}

input[type=checkbox]:hover:before{
	border-color: var(--color-black);
}

input[type=checkbox]:checked:before {
	background-color: var(--color-black);
	border-color: var(--color-black);
}

.mobile__show {
	display: none;
}

body > iframe {
	visibility: hidden;
}

body div.usabilla_live_button_container > iframe {
	visibility: visible;
}

@media screen and (max-width: 640px) {
	.content .content__title {
		font-size: var(--font-size-heading-2);
		margin: 20px 0 20px 0;
	}
	.mobile__hide {
		display: none;
	}
	.mobile__show {
		display: inline-block;
	}

	.content--card:last-of-type {
		margin-bottom: 0;
	}

	.side--bar > a:last-of-type {
		display: block;
		margin-bottom: 30px;
	}
}

.mobile__show.hide-menu {
	display: none;
}

.title-addon {
	position: absolute;
	top: 30px;
	right: 20px;
	font-size: 16px;
}

.impersonation-close {
	position: absolute;
	top: 58px;
	right: 310px;
}

.link-changelogin {
	position: absolute;
	top: 30px;
	right: 20px;
	font-size: 16px;
	text-align: right;
	line-height: 22px;
}

.grey-box {
	background-color: var(--color-grey-verylight);
}

.padding-horizontal {
	padding: 3rem 0;
}

.padding-sides {
	padding: 0 1rem;
}

@media screen and (max-width: 768px) {
	.title-addon {
		top: 0;
		right: 0;
		position: relative;
		font-size: 14px;
	}

	.link-changelogin {
		top: 0;
		right: 0;
		position: relative;
		font-size: 14px;
	}

	.impersonation-close {
		top: 43px;
		right: 200px;
	}
}

.displayhidden-ariavisible {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

@media screen and (max-width: 450px) {
	.impersonation-close {
		top: 70px;
		right: 1.5rem;
	}
}