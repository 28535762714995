@import "supplemental/locals.css";

.notice {
	margin: var(--notice-margin);
	padding: var(--notice-padding);
	background: var(--notice-background);
	border-radius: var(--notice-border-radius);
	color: var(--notice-color);
	font-size: var(--notice-font-size);
}
