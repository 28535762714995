@import "@css/supplemental/notice.css";

.notice {
	box-shadow: var(--shadow-small);
}

.card .notice {
	text-align: center;
}

.notice--primary {
	position: relative;
	text-align: center;

	background: transparent;
	border: 2px solid var(--color-blue);
	border-radius: 5px;
	color: #000;
}

.notice.arrow {
	margin-bottom: 30px;
}

.notice.arrow > button {
	padding: 0;
	background: transparent;
	position: absolute;
	width: 30px;
	height: 30px;
	left: calc(50% - 15px);
	bottom: -30px;
}

.notice--primary strong {
	color: var(--color-primary);
}

.notice--primary a {
	color: var(--color-black);
	font-weight: var(--font-weight-bold);
	text-decoration: none;
}

.notice--secondary {
	background: var(--color-blue-verylight);
	border: 2px solid var(--color-blue);
	border-radius: 10px;
	color: var(--color-black);
	text-align: center;
}

/* .notice--primary img {
	position: absolute;
	left: 2rem;
} */

.card .notice--secondary {
	position: relative;
	padding-left: 4rem;
	margin: 0 4rem;
	font-size: 1.4rem;
	text-align: left;
}

.card .notice--secondary img {
	position: absolute;
	left: 1rem;
}