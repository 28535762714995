#comm-pref-modal input[type=radio]:before {
	border: 2px solid #E5E5E5;
	left: 2px;
}

#comm-pref-modal input[type=radio]:checked:before {
	border: 5px solid #000;
	height: 6px;
	width: 6px;
}

#comm-pref-modal .input__holder {
	padding-top: 10px;
}

#comm-pref-modal label {
	padding-right: 20px;
}

#comm-pref-modal .modal__box {
	padding: 20px 12px;
}

#comm-pref-modal .modal__content ul {
	list-style: square;
}

#comm-pref-modal .modal__content ul ::marker {
	color: #FFBD00;
}

#comm-pref-modal .modal__header {
	background: none;
	color: #000000;
	font-family: Memphis;
}

button.button.button--primary {
	background: #2A292E;
	color: #FFFFFF;
}

.input-label.expanded {
	margin: 20px 0 5px 0;
}

p.error {
	color: red;
}
