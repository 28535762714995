@import "variables.css";

.insurance-table {
    margin: 40px 0 30px 0;
}

.row.insurance-table__row {
    margin: 0;
    padding: 0;
    font-size: 14px
}

.row.insurance-table__row.insurance-table__row--heading {
    margin: 20px 20px 10px 20px;
}

.row.insurance-table__row.insurance-table__row--heading .row__item {
    font-weight: 700;
}

.insurance-table__row .row__item {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: var(--font-family-bold);
}

.insurance-table__row .row__item .button--secondary {
    margin-left: 20px;
}

.insurance-table__row .row__item div .item__value {
    display: inline-block;
    margin-right: 20px;
}

.insurance-table__row p.item__value {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.insurance-table__row p.item__value.word__break {
    word-break: break-word;
}

.insurance-table__row p.item__value .item__subtext {
    display: block;
    font-size: 13px;
}

.insurance-table__heading {
    font-size: var(--font-size-heading-3);
    margin: 0 20px;
    padding-top: 20px;
}

.insurance-table__content p.no-requests,
.insurance-table__content p.account-info {
    margin: 10px 20px;
    padding-bottom: 15px;
    font-size: 14px;
}

.insurance-table__content p a.link-account--icon {
    background: url("~assets/svg/ic-external-link.svg") no-repeat right;
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 22px;
    width: 162px;
}

.policy--buttons {
    margin-top: 20px;
}

.policy--buttons a {
    padding-right: 20px;
}

.insurance-table__content .accordion__content {
    padding-bottom: 5px;
}

.welcome {
    font: var(--font-heading-3);
}

.notice.first-notice {
    margin-top: 20px;
}

@media only screen and (max-width: 640px) {
    .insurance-table__content {
        margin-bottom: 20px;
    }

    .row.insurance-table__row.insurance-table__row--heading {
        display: none;
    }

    .row.insurance-table__row {
        padding: 0;
        border-top: none;
    }

    .insurance-table__row .row__item {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
    }

    .insurance-table__row .row__item.mobile__hide {
        display: none;
    }

    .insurance-table__row .row__item:first-child {
        margin-top: 0;
    }

    .insurance-table__row .row__item .item__title {
        margin-bottom: 0;
        margin-top: 10px;
        font-family: var(--font-family-semibold);
        display: inline-block;
    }

    .insurance-table__row .row__item div .item__value,
    .item__value .mobile__show {
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
    }

    .insurance-table__heading {
        font-size: var(--font-size-heading-3);
    }

    .row.insurance-table__row .row__item:nth-child(3) {
        max-width: 100%;
        flex-basis: 100%;
    }

    .row.insurance-table__row .row__item--expired, .row.insurance-table__row .row__item:nth-child(4) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 25px;
    }

    .row.insurance-table__row p.item__value {
        margin-top: 0;
    }

    .insurance-table__row .row__item .button--primary {
        margin-top: 15px;
    }

    .policy--buttons {
        margin-top: 5px;
    }

    .policy--buttons a {
        padding-right: 20px;
        display: block;
    }

    .policy--buttons > a:first-of-type {
        margin-top: 15px;
    }

    div.welcome {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .insurance-table__row .row__item {
        margin-bottom: 0;
    }

    .insurance-table__row .row__item:nth-child(3) {
        max-width: 100%;
        flex-basis: 25%;
    }

    .insurance-table__row .row__item:nth-child(5) {
        flex-basis: 0%;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .insurance-table__row .row__item--expired {
        max-width: 100%;
        flex-basis: 25%;
    }

    .insurance-table__row .row__item--expired p.item__value {
        margin-right: 10px;
    }

    .insurance-table__heading {
        margin-bottom: 20px;
    }
}