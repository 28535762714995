@import "@css/variables.css";
/* create/overwrite global variables */
@font-face {
	font-family: "Memphis";
	font-weight: 400;
	src: url("~assets/fonts/91f3816a-177c-499c-956b-ca6180e1aa50.woff2") format("woff2"),
		url("~assets/fonts/ff4c6fb5-c122-483a-ba6a-51abbbe506c0.woff") format("woff"),
		url("~assets/fonts/ae865674-e055-4c66-9eda-26f36ed89384.ttf") format("truetype");
}

@font-face {
	font-family: "Memphis Bold";
	font-weight: 700;
	src: url("~assets/fonts/1e4fd5e2-78ae-4df4-bb6d-dcf0bc861253.woff2") format("woff2"),
		url("~assets/fonts/b7dcb861-4dfa-4b8c-b576-c9eea5f48c96.woff") format("woff"),
		url("~assets/fonts/e492e062-6fef-427a-9a81-020ff99a121f.ttf") format("truetype");
}

@font-face {
	font-family: "Avenir";
	font-weight: 400;
	src: url("~assets/fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),
		url("~assets/fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),
		url("~assets/fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
}

:root {
	/* colors */
	--color-primary-light: var(--color-blue);
	/* black */
	--color-primary: #000000;
	/* dark blue */
	--color-primary-dark: #002f6b;

	/* branding yellow light */
	--color-branding: #fff8e3;
	/* branding yellow dark */
	--color-branding-dark: #eda50f;

	--color-orange-verylight: #f7caab;
	--color-orange: #ffbd00;
	--color-red: #fa6b7b;
	--color-blue-verylight: #e6f4f5;
	--color-blue-light: #96dcfa;
	--color-blue: #007ab3;

	--color-green: #4ab286;

	--color-white: #ffffff;
	--color-grey-verylight: #f6f6f7;
	--color-grey-light: #e5e5e5;
	--color-grey: #9d9d9d;
	--color-grey-dark: #565656;
	--color-black: #000000;

	--color-grey-blue: #f6f6f7;

	/* --color-warning: #C90000; */


	--color-bluegreen-light: #E2F2F0;
	--color-bluegreen: #009D94;

	--color-purple: #935291;
	--color-purple-light: #EFDDEE;

	/* gradients */
	/* linear-gradient(180deg,#fffaea 0,#fff8e3)
	--gradient-primary: linear-gradient(180deg, var(--color-branding) 0, var(--color-branding));
	--gradient-secondary: var(--color-primary); */

	/* shadows */
	--shadow-small: 0 2px 4px 0 rgba(225, 220, 203, 0.40), 0 2px 4px 0 rgba(42, 41, 46, 0.16);
	--shadow-large: 0 0 16px 0 rgba(195, 182, 141, 0.16), 0 0 16px 0 rgba(42, 41, 46, 0.08);
	--shadow-asr-logo: 0 4px 16px #0000001f;

	/* fonts */
	--font-family: "Avenir", sans-serif;
	--font-family-secondary: "Memphis", sans-serif;
	--font-family-bold: "Avenir", sans-serif;
	--font-family-regular: "Avenir", sans-serif;
	--font-family-semibold: "Avenir", sans-serif;

	--font-weight-bold: 700;
	--font-weight-light: 300;
	--font-weight: 400;

	--font-size-small: 12px;
	--font-size-smaller: 14px;
	--font-size: 16px;
	--font-size-heading-3: 20px;
	--font-size-heading-2: 32px;
	--font-size-heading-1: 50px;
	--font-size-heading: var(--font-size-heading-1);

	/* font-style font-variant font-weight font-size/line-height font-family */
	--font-small: normal normal var(--font-weight) 16px/24px var(--font-family);
	--font: normal normal var(--font-weight) var(--font-size)/24px var(--font-family);
	--font-large: normal normal var(--font-weight) var(--font-size-large)/32px var(--font-family);
	--font-heading-3: normal normal var(--font-weight) 22px/22px var(--font-family-secondary);
	--font-heading-2: normal normal var(--font-weight) 28px/32px var(--font-family-secondary);
	--font-heading-1: normal normal var(--font-weight) 38px/44px var(--font-family-secondary);

	/* transitions */
	/* --transition-duration-short: 200ms; */
	/* --transition-duration-long: 500ms; */

	/* borders */
	--border-radius: 0;

	--header-height: 11rem;
	--footer-height: 14rem;

	--breakpoint-desktop-l: 1440px;
	--breakpoint-desktop: 1140px;
	--breakpoint-tablet: 768px;
	--breakpoint-mobile-l: 425px;
	--breakpoint-mobile-m: 375px;
	--breakpoint-mobile-s: 320px;
}